<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
            <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

            </v-breadcrumbs>
            </div>
        </v-row><br><br>
        <br />
        <v-card class="card">
            <v-card-title class="heading justify-center">
                <span>Book Status</span>
            </v-card-title>
            <div v-if="init_loading">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
            <v-card-text>
                <div class="add-section">
                    <div style="text-align: center;">
                        <v-col>
                            <v-form ref="form">
                                <label class="add-text">Add New Book Status</label><span style="color: red;"> *</span>
                                <v-text-field class="text" v-model="bookStatus" outlined dense :rules="[rules.required]"
                                    autocomplete="off" clearable required></v-text-field>
                                <!-- <v-text-field class="text" v-model.trim="bookStatus" :items="bookStatus_SA" item-text="displaynames" outlined  dense :rules="[rules.required]" clearable required ></v-text-field> -->
                                <label class="add-text">Display Name</label>
                                <v-text-field class="text" v-model="display_name" outlined dense :rules="[rules.required]"
                                    autocomplete="off" clearable required></v-text-field>
                                <v-btn color="success" @click="addBookStatus">Add</v-btn>
                            </v-form>
                        </v-col>
                    </div>
                </div>

                <template>
                    <div class="text-center">
                        <v-dialog v-model="dialog" width="500">
                            <v-card>
                                <v-card-title class="grey lighten-2">
                                    Edit Book Status
                                </v-card-title>

                                <v-card-text style="padding-bottom: 0px;">
                                    <label class="add-text">Add New Book Status</label><span style="color: red;"> *</span>
                                    <v-text-field class="text" v-model="editName" outlined dense :rules="[rules.required]"
                                        autocomplete="off" clearable required></v-text-field>
                                    <label class="add-text">Display Name</label>
                                    <v-text-field class="text" v-model="bookStatusName" outlined dense
                                        :rules="[rules.required]" clearable required></v-text-field>
                                    <!-- <v-combobox class="text" v-model.trim="bookStatusName" :items="bookStatus_SA" item-text="bookStatus" outlined  dense :rules="[rules.required]" clearable required ></v-combobox> -->
                                </v-card-text>

                                <!-- <v-divider></v-divider> -->

                                <div class="text-center">
                                    <v-spacer></v-spacer>
                                    <v-btn class="m-3" @click="dialog = false">
                                        Close
                                    </v-btn>
                                    <v-btn class="m-3" @click="saveEdit()">Update</v-btn>
                                </div>
                            </v-card>
                        </v-dialog>
                    </div>
                </template>

                <div v-if="loader">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
                <template v-if="bookStatus_list != null">
                    <v-data-table :headers="headers" :items="bookStatus_list" :search="search" class="elevation-1 nowrap">
                        <template v-slot:top>
                            <v-toolbar flat color="white">
                                <v-spacer></v-spacer>
                                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search in table" single-line
                                    hide-details></v-text-field>
                            </v-toolbar>
                        </template>
                        <template v-slot:item.edit="{ item }">
                            <!-- <v-icon v-if="item.edit==true" color="success" class="mr-2">mdi-check-circle-outline</v-icon> -->
                            <v-icon v-if="item" class="mr-2" @click="updateBookStatus(item)">mdi mdi-pencil-box</v-icon>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <!-- <v-btn :loading="edit_btn_load" @click="editItem(item)" class="link" color="info"> <v-icon title="Activate/Deactivate the User Type">mdi-recycle</v-icon></v-btn> -->
                            <v-switch v-model="item.bookStatusIsactive" @change="editItem(item)"></v-switch>
                        </template>
                        <template v-slot:item.delete="{ item }">
                            <v-icon v-if="item" class="mr-2" @click="deleteBookstatus(item)"> mdi mdi-delete</v-icon>
                        </template>
                    </v-data-table>
                </template>
                </div>
            </v-card-text>
            </div>
        </v-card>
        <!-- <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay> -->
    </div>
</template>

<script>
import axios from "axios";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
    data: () => ({
        init_loading: false,
        loader: false,
        bookStatus_SA: null,
        snackbar_msg: "",
        snackbar: false,
        color: "",
        org: null,
        dialog: false,
        search: "",
        bookStatus: null,
        display_name: null,
        bookStatusId: null,
        bookStatusName: null,
        userType: null,
        editName: null,
        items: [
            {
                text: 'Dashboard',
                disabled: false,
                href: '/home',
            },
            {
                text: localStorage.getItem("jhhjbyj"),
                disabled: false,
                href: '/sublink',
            },
            {
                text: 'Admin Master',
                disabled: false,
                href: '/admin-master',
            },
            {
                text: 'Book Status',
                disabled: true,
                href: '',
            },
        ],
        edit_btn_load: false,
        rules: {
            required: (value) => !!value || "Required.",
        },
        bookStatus_list: null,
        headers: [
            { text: "Sr no", value: "srno" },
            { text: "Name", value: "bookStatus" },
            { text: "Display Name", value: "display_name" },
            { text: "edit", value: "edit" },
            { text: "Toggle", value: "actions", sortable: false },
            { text: "Delete", value: "delete" },
        ],
    }),
    watch: {},
    methods: {
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
        editItem(item) {
            console.log(item);
            this.loader = true;
            axios
                .post("/admin/bookStatusIsactive", item)
                .then(() => {
                    // this.sec_mode_list = res.data.user_list;
                    this.loader = false;
                    this.showSnackbar("#4CAF50", "Book status updated successfully...");
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    console.log(error);
                })
                .finally(() => {
                    this.loader = false;
                    this.edit_btn_load = false;
                    axios;
                });
        },

        updateBookStatus(item) {
            this.dialog = true;
            this.bookStatusId = item.bookStatusId;
            this.editName = item.bookStatus;
            this.bookStatusName = item.display_name;
        },

        saveEdit() {
            // alert("doneeeeeeee");
            if (this.editName) {
                this.editName = this.editName.trim()
            }
            {
                if (this.bookStatusName) {
                    this.bookStatusName = this.bookStatusName.trim()
                }
                console.log("logssssssssss");
                console.log(this.bookStatusName);
                const data = {
                    // bookStatusName: this.bookStatusName,
                    bookStatusId: this.bookStatusId,
                    bookStatus: this.editName,
                    display_name: this.bookStatusName,
                };
                if (this.editName && this.bookStatusName) {
                    this.loader = true;
                    axios
                        .post("/admin/editBookStatus", data)
                        .then((res) => {
                            if (res.data.status == "success") {
                                this.loader = false;
                                //this.sec_mode_list = res.data.user_list;
                                this.showSnackbar("#4CAF50", "Book status updated successfully...");
                                this.editName = "",
                                    this.bookStatusName = "",
                                    this.onload();
                                this.dialog = false;
                            } else {
                                this.loader = false;
                                this.showSnackbar("#b71c1c", res.data.status);
                            }
                        })
                        .catch((error) => {
                            this.showSnackbar("#b71c1c", "Something went wrong!!!");
                            console.log(error);
                        })
                        .finally(() => {
                            // this.edit_btn_load = false;
                            // this.editUserType = null;
                            // axios;
                        });
                } else {
                    this.showSnackbar("#b71c1c", "Please enter Book status");
                    this.$refs.form.validate();
                }
            };
        },
        deleteBookstatus(item) {
            const data = {
                bookStatusId: item.bookStatusId,
            };
            axios
                .post("/admin/deleteBookstatus", data)
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.showSnackbar("#4CAF50", "BookStatus Deleted Successfully  !!");
                        this.onload();
                    }
                    //     else if(res.data.msg == "exp"){
                    //        this.showSnackbar("#b71c1c", "Can not be Deleted!!!");                          
                    //    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    window.console.log(error);
                });
        },

        onload() {
            this.init_loading = true
            axios
                .post("/admin/getBookStatus")
                .then((res) => {
                    // this.org = res.data.org;
                    // this.bookStatus_list = res.data.bookStatus;
                    // this.bookStatus_SA = res.data.bookStatus_SA
                    // console.log(res.data);
                    this.init_loading = false
                    //this.showSnackbar("#4CAF50","Success")
                    if (res.data.msg == "200") {
                        this.bookStatus_list = res.data.bookStatus_list;
                    } else {
                        this.init_loading = false;
                    }
                    // .catch((error) => {
                    //     this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    //     console.log(error);
                    // })
                    // .finally(() => {
                    //     this.overlay = false

                });
        },


        addBookStatus() {
            // alert("success");
            // alert(this.bookStatus);
            //  alert(this.display_name);
            // if(this.bookStatus){
            // this.bookStatus = this.bookStatus.trim()
            // }
            const data = {
                bookStatus: this.bookStatus,
                display_name: this.display_name,
                // bookStatus_name: this.bookStatus.bookStatus,
            };

            if (this.bookStatus) {
                this.loader = true;
                axios
                    .post("/admin/addBookStatus", data)
                    .then((res) => {
                        // this.org = res.data.org;
                        // this.bookStatus_list = res.data.bookStatus_list;
                        if (res.data.msg == "200") {
                            this.loader = false;
                            this.showSnackbar("#4CAF50", "Book status added successfully...");
                            // this.userType = null;
                            // this.$refs.form.reset()
                            this.editName = "",
                                this.bookStatusName = "",
                                this.onload();
                        } else {
                            this.loader = false;
                            // this.onload()
                            this.showSnackbar("#b71c1c", res.data.status);
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => { });
            } else {
                this.showSnackbar("#b71c1c", "Please enter Book status");
                this.$refs.form.validate()
            }
        },

    },
    mounted() {
        this.onload();
    },

};

</script>
<style scoped>
.heading {
    background-color: #3f51b5;
    padding: 0.3rem;
    color: white;
}

.v-icon {
    cursor: pointer;
}

.text>>>.v-input__slot {
    background: white !important;
    border-color: hsla(190, 99%, 30%, 1) !important;
}
</style>
